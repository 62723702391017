import { provideTransloco, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import { firstValueFrom } from 'rxjs';

var availableLangs = ['en', 'fr', 'de'];
var savedLang = localStorage.getItem('preferLang');
var userLang: string = savedLang
  ? savedLang
  : navigator.language
    ? (navigator.language + '').split('-')[0]
    : 'en';

const defaultLang = availableLangs.includes(userLang) ? userLang : 'en';
export function preloadTranslation(transloco: TranslocoService) {
  return function () {
    transloco.setActiveLang(defaultLang);
    return firstValueFrom(transloco.load(defaultLang));
  };
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs,
        defaultLang,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [TranslocoService],
      useFactory: preloadTranslation,
    },
  ],
})
export class TranslocoRootModule {}
