import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  ProtectedResourceScopes
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TUI_TEXTFIELD_LABEL_OUTSIDE } from '@taiga-ui/core';
import { DataTablesModule } from 'angular-datatables';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { environment } from 'src/environment/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftSideMenuComponent } from './core/components/left-side-menu/left-side-menu.component';
import { SpinnerComponent } from './core/components/spinner/spinner.component';
import { GreenInterceptor } from './core/interceptors/green.interceptor';
import { LicenseInterceptor } from './core/interceptors/license.interceptor';
import { MsalOverridedInterceptor } from './core/interceptors/msal-overrided.interceptor';
import { SpinnerInterceptor } from './core/interceptors/spinner.interceptor';
import { AuthActions } from './core/store/actions';
import { GetUserDetailsEffects } from './core/store/effects';
import { authReducer } from './core/store/reducer';
import { AppState } from './core/store/state';
import { TranslocoRootModule } from './core/transloco/transloco-root.module';
import { LetDirective } from './core/utils/let-directive';
import { apiBaselineDocumentsProtectedResources } from './modules/baseline-documents/services/baseline-documents-api.service';
import { CounterpartiesModule } from './modules/counterparties/counterparties.module';
import { DialogsModule } from './modules/dialogs/dialogs.module';
import { apiDocumentTypesProtectedResources } from './modules/doc-types/services/document-types-api.service';
import { DocumentsModule } from './modules/documents/documents.module';
import { apiDocumentsProtectedResources } from './modules/documents/services/documents-api.service';
import { LocalizationModule } from './modules/localization/localization.module';
import { LoginComponent } from './modules/login/components/login.component';
import { apiUserProtectedResources } from './modules/login/services/user-api.service';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { apiClausesProtectedResources } from './modules/playbooks/services/playbooks-api.service';
import { QuestionsModule } from './modules/questions/questions.module';
import { apiKeyQuestionsProtectedResources } from './modules/questions/services/questions-api.service';
import { TemplatesModule } from './modules/templates/templates.module';
import { PipesModule } from './shared/pipes/pipes.module';
import { apiDocumentStatusesProtectedResources } from './shared/services/document-statuses-api.service';
import { apiLanguagesProtectedResources } from './shared/services/languages-api.service';
import { apiPositionsProtectedResources } from './shared/services/positions-api.service';
import { apiReportingProtectedResources } from './shared/services/reporting-api.service';
import { SharedModule } from './shared/shared.module';
import { TAIGA_UI_MODULES } from './shared/taiga.module';

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(environment.msalConfig); // todo
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(
    environment.protectedResources.apiUser.endpoint,
    apiUserProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiDocuments.endpoint,
    apiDocumentsProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiBaselineDocuments.endpoint,
    apiBaselineDocumentsProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiDocumentTypes.endpoint,
    apiDocumentTypesProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiPositions.endpoint,
    apiPositionsProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiParties.endpoint,
    apiPositionsProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiClauses.endpoint,
    apiClausesProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiDocumentStatuses.endpoint,
    apiDocumentStatusesProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiKeyQuestions.endpoint,
    apiKeyQuestionsProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiLanguages.endpoint,
    apiLanguagesProtectedResources,
  );

  protectedResourceMap.set(
    environment.protectedResources.apiReporting.endpoint,
    apiReportingProtectedResources,
  );

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}
/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: environment.loginRequest,
  };
}
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [AppComponent, SpinnerComponent, LeftSideMenuComponent, LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    LocalizationModule,
    StoreModule.forRoot({ rootState: authReducer }),
    EffectsModule.forRoot([GetUserDetailsEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    DocumentsModule,
    QuestionsModule,
    TemplatesModule,
    TranslocoRootModule,
    TranslocoModule,
    DataTablesModule,
    PipesModule,
    DialogsModule,
    ModalModule.forRoot(),
    LetDirective,
    TAIGA_UI_MODULES,
    MsalModule,
    NotificationsModule,
    CounterpartiesModule,
  ],
  providers: [
    [
      {
        provide: APP_INITIALIZER,
        useFactory: initApp,
        deps: [Store],
        multi: true,
      },
    ],
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: TUI_TEXTFIELD_LABEL_OUTSIDE, useValue: { labelOutside: false } },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: HTTP_INTERCEPTORS, useClass: MsalOverridedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LicenseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GreenInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(private modalService: BsModalService) {
    this.modalService.config.keyboard = true;
    this.modalService.config.backdrop = 'static';
    this.modalService.config.ignoreBackdropClick = true;
  }
}

export function initApp(store$: Store<AppState>) {
  return () => {
    return new Promise<void>(async (resolve) => {
      //Office.initialize = function () {};

      let href = window.location.href;

      if (href.indexOf('playbook?clauseName=') !== -1) {
        let clauseNameToBeFiltered = decodeURI(href.split('playbook?clauseName=')?.[1]);

        if (clauseNameToBeFiltered) {
          store$.dispatch(AuthActions.setClauseNameToBeFiltered({ name: clauseNameToBeFiltered }));
        }
      }

      if (href.indexOf('?code=') !== -1) {
        const codeExpression = decodeURI(href.split('?code=')?.[1]);

        if (codeExpression) {
          store$.dispatch(AuthActions.setAuthCode({ authCode: codeExpression }));
        }
      }

      resolve();
    });
  };
}
